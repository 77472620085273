<template>
  <router-view />
  <van-tabbar v-show="isShow" v-model="active">
    <van-tabbar-item icon="home-o" to="/payment">首页</van-tabbar-item>
    <van-tabbar-item icon="user-o" to="/user">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script >
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const active = ref(0);
    const isShow = ref(false);
    const router = useRouter();

    watch(() => {
      /* eslint-disable */
      upsdk.pluginReady(function () {
        // 前端API调用
        upsdk.getUserLogoutDate({
          success: function (data) {
            if (data.userLogoutDate) {
              if (data.userLogoutDate != localStorage.getItem("logoutDate")) {
                localStorage.clear();
                localStorage.setItem("logoutDate", data.userLogoutDate);
              } 
            } 
          },
          fail: function () {
            // 插件调用失败
          },
        });
      });
      let path = router.currentRoute.value.fullPath;
      if (path.includes("/payment") || path == "/user") {
        isShow.value = true;
      } else {
        isShow.value = false;
      }
    });

    return {
      active,
      isShow,
    };
  },
};
</script>

<style lang="less">
body {
  background-color: #f2f2f2;
}

.footer {
  background-color: white;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  font-size: 12px;
  color: gray;
}
</style>
