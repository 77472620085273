<template>
  <!-- <div><van-nav-bar title="登录"></van-nav-bar></div> -->

  <div class="login">
    <van-form style="margin-top: 100px" @submit="onSubmit">
      <div style="width: 60px; height: 60px; margin: 0px auto">
        <img
          style="width: 60px; height: 60px; margin: 0px auto"
          src="../assets/logo.png"
        />
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <span style="color: #1989fa; font-size: 20px; font-weight: 580">校易收</span>
        <span style="margin-top: 30px; color: #999999; font-size: 20px; font-weight: 500"
          >让校园缴费更轻松</span
        >
      </div>

      <div style="margin-top: 30px"></div>
      <van-cell-group inset>
        <van-field
          v-model="username"
          label="账号"
          placeholder="请输入登录账号"
          maxlength="11"
          :rules="[{ required: true }]"
          clearable
        />
        <van-field
          v-model="password"
          type="password"
          label="密码"
          placeholder="请输入密码"
          maxlength="18"
          clearable
          :rules="[{ required: true }]"
        />
      </van-cell-group>
      <div style="margin: 20px 20px">
        <span
          class="forget"
          style="float: right; color: #999999; font-size: 12px"
          @click="toResetPwd"
          >忘记密码?</span
        >
      </div>
      <div style="margin: 60px 18px">
        <van-button round block type="primary" native-type="submit"> 登录 </van-button>
      </div>
    </van-form>
    <div>
      <span
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          bottom: 10px;
          width: 100%;
          height: 30px;
          font-size: 12px;
          color: #999999;
        "
        >山东眯去来网络科技有限公司</span
      >
    </div>
  </div>
  <van-dialog show-cancel-button :before-close="onBeforeClose" @confirm="handleConfirm">
  </van-dialog>
</template>

<script>
import { ref, onMounted } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import api from "../service/api";
import { encryption, safeName } from "../common/js/utils";

export default {
  setup() {
    const username = ref();
    const password = ref();
    const router = useRouter(); //传值
    const child = ref();
    const code = ref("");

    onMounted(() => {
      /* eslint-disable */
      upsdk.pluginReady(function () {
        upsdk.appletAuth({
          success: function (data) {
            code.value = data.code;
          },

          fail: function (error) {
            Toast(error.errmsg);
          },
        });
        upsdk.setTitleStyle({
          backBtnVisible: "0",
          navBackgroundColor: "0xff1989fa",
        });
        upsdk.setNavigationBarTitle({
          title: "校易收",
        });
      });
    });
    const toResetPwd = () => {
      router.push({
        path: "/resetpwd",
      });
    };

    const onSubmit = () => {
      // if (!getQuery("code")) {
      //   Toast("未获取到code");
      //   localStorage.clear();
      //   window.location.href = url;
      //   return;
      // }
      if (!isPone(username.value)) {
        Toast("手机号码格式不正确");
        return;
      }
      if (!isPwd(password.value)) {
        Toast("密码格式为6-18位数字字母或其组合");
        return;
      }

      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });

      api
        .login({
          phone_no: username.value,
          password: password.value,
        })
        .then((res) => {
          if (res.data.code == 10018) {
            localStorage.setItem("sid", res.data.result.sid);
            localStorage.setItem("phone", username.value);

            localStorage.setItem("pwd", encryption(password.value));
            let result = res.data.result;
            let userName = result.user_name;
            localStorage.setItem("userName", userName);
            let childList = result.data;
            if (childList.length == 0) {
              //没有孩子，退出
              Toast("此账号没有配置学生信息");
              return;
            }
            child.value = childList[0];
            localStorage.setItem("childId", child.value.child_id);
            localStorage.setItem("childName", safeName(child.value.real_name));
            localStorage.setItem("className", child.value.class_name);
            localStorage.setItem("schoolName", child.value.school_name);

            toPayment();
            // getOpenId(code.value);
          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };

    function toPayment() {
      router.replace({
        path: "/payment",
        // query: {
        //   child_id: child.value.child_id,
        //   child_name: child.value.real_name,
        //   school_name: child.value.school_name,
        //   class_name: child.value.class_name
        //     ? child.value.class_name
        //     : "未加入班级",
        // },
      });
    }

    function isPone(phone) {
      var regPhone = /^[1][0-9]{10}$/;
      if (regPhone.test(phone)) {
        return true;
      } else {
        return false;
      }
    }

    function isPwd(pwd) {
      var regPwd = /^[a-zA-Z0-9~!@#$%^&*?]{6,18}$/;
      if (regPwd.test(pwd)) {
        return true;
      } else {
        return false;
      }
    }

    return {
      username,
      password,
      onSubmit,
      toResetPwd,
      code,
    };
  },
};
</script>
