import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { axios } from "axios"; //加大括号和不加的区别？？？？

import {
  ConfigProvider,
  Button,
  Form,
  Field,
  CellGroup,
  Toast,
  List,
  Cell,
  Tab,
  Tabs,
  Popup,
  NavBar,
  Dialog,
  Tabbar, 
  TabbarItem,
  Empty,
} from "vant";

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(Button)
  .use(ConfigProvider)
  .use(Form)
  .use(Field)
  .use(CellGroup)
  .use(Toast)
  .use(List)
  .use(Cell)
  .use(Tab)
  .use(Tabs)
  .use(Popup)
  .use(axios)
  .use(NavBar)
  .use(Dialog)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Empty)
  .mount("#app");
