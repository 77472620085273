import { http } from "./service.js";
const api = {
  login(data) {
    //登录
    return http.post("/api/user/login", data);
  },
  getChargeList(data) {
    //获取缴费单列表
    return http.post("/api/charge/getList", data);
  },
  getPaymentDetail(data) {
    //获取缴费单详情
    return http.post("/api/charge/getDetailed", data);
  },
  createAbcOrder(data) {
    //生成农行订单去缴费
    return http.post("/api/WeChatSubscriptionPay/AbcPay", data);
  },
  createUnionPayOrder(data) {
    //生成银联订单去缴费--app和云闪付小程序
    return http.post("/api/BankPay/chinaumsAPPPay", data);
  },

  getChildren() {
    //获取用户的孩子
    return http.get("/api/child/get");
  },
  getOpenId(data) {
    //获取微信openId
    return http.post("/api/WeChatSubscriptionPay/getWxOpenId", data);
  },
  updateOpenID(data) {
    //重新绑定用户openId
    return http.post("/api/WeChatSubscriptionPay/updateOpenId", data);
  },
  // resetPwd(data){//重置密码
  // 	return http.post("/api/user/find_reset_pwd",data);
  // },
  // getSms(data){//获取短信验证码
  // 	return http.post("/api/user/verify",data);
  // }
};
export default api;
