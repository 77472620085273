import axios from "axios";
import qs from "qs";
import { Toast } from "vant";
import {decryption} from "../common/js/utils";
const url = ""

// 设置post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

//创建axios实例，请求超时时间为120秒
const instance = axios.create({
  timeout: 120000,
});

// 请求发起前拦截
instance.interceptors.request.use(
  (config) => {
    if (config.url.search("/user/login") == -1) {
      //登录接口不拼接sid
      config.url += "?sid=" + localStorage.getItem("sid");
      // console.log("请求拦截url", config.url);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截（请求返回后拦截）
instance.interceptors.response.use(
  (response) => {
    if (response.data.code == 10031) {
      //sid失效，重新登录获取后再次调用原接口
      return new Promise((resolve) => {
        reLogin(resolve, response.config);
      });
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * 重新登录后调用原接口
 * @param {*} resolve
 * @param {*} config
 */
function reLogin(resolve, config) {
  if (!localStorage.getItem("phone")) {
    // console.log(config.url);
    localStorage.clear();
    window.location.replace(url);
  }
  http
    .post("/api/user/login", {
      phone_no: localStorage.getItem("phone"),
      password: decryption(localStorage.getItem("pwd")),
    })
    .then((res) => {
      if (res.data.code == 10018) {
        localStorage.setItem("sid", res.data.result.sid);
        let childList = res.data.result.data;
        let childId = localStorage.getItem("childId");
        let child;
        for (let i = 0; i < childList.length; i++) {
          if (childList[i].child_id == childId) {
            child = childList[i];
            break;
          }
        }
        if (!child) {
          child = childList[0];
          localStorage.setItem("childId", child.child_id);
        }
        config.url = replaceParamVal(
          config.url,
          "sid",
          localStorage.getItem("sid")
        );
        //调用原接口
        axios(config)
          .then((res) => {
            resolve(res); //将处理后的response传给回调函数
          })
          .catch((err) => {
            Toast("service.js:" + err.message);
            localStorage.clear();
            window.location.replace(url);
          });
      } else {
        localStorage.clear();
        window.location.replace(url);
      }
    })
    .catch((err) => {
      Toast(err.message);
      localStorage.clear();
      window.location.replace(url);
    });
}

/**
 * 替换url中的参数
 * @param {*} oUrl
 * @param {*} paramName
 * @param {*} replaceWith
 * @returns
 */
function replaceParamVal(oUrl, paramName, replaceWith) {
  var re = eval("/(" + paramName + "=)([^&]*)/gi");
  var nUrl = oUrl.replace(re, paramName + "=" + replaceWith);
  return nUrl;
}

//按照请求类型对axios进行封装
const http = {
  get(url) {
    return instance.get(url);
  },
  post(url, data) {
    return instance.post(url, qs.stringify(data));
  },
};
export { http };
