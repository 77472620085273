import { createStore } from "vuex";

export default createStore({
  state: {
    // sid:"",
  },
  mutations: {
    // sidKey(state,value){
    //   state.sid = value;
    // }
  },
  actions: {},
  modules: {},
});
